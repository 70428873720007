// import { Box } from '@/components/box';
import { CapsizedText as Text } from '@/components/text';
import { styled } from '@/stitches.config';
import { Close } from '@/components/icons';
import { useExperienceStore } from '../../store';

export function ToggleContent() {
  const { showContent, updateShowContent, updateExploreState } =
    useExperienceStore((state) => ({
      updateShowContent: state.updateShowContent,
      showContent: state.showContent,
      updateExploreState: state.updateExploreState,
    }));

  function onClickHandler() {
    if (!showContent) {
      updateExploreState('orb');
    } else {
      updateExploreState('cluster');
    }

    updateShowContent(!showContent);
  }

  return (
    <>
      {/* <DesktopContainer>
        <Box
          onClick={onClickHandler}
          css={{
            fontSize: '$1',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Text
            as="span"
            size={2}
            css={{
              marginRight: '$1',
            }}
          >
            {showContent ? 'Hide' : 'Show'}
          </Text>
          <ChevronDownIcon
            width={20}
            height={20}
            style={{
              transition: '.2s ease',
              transform: showContent ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </Box>
      </DesktopContainer> */}

      <MobileContainer>
        {showContent && (
          <BrowseButton onClick={onClickHandler}>
            <Text
              as="span"
              size={1}
              css={{
                display: 'block',
                transform: 'translateY(-1px)',
              }}
            >
              Explore by color
            </Text>
          </BrowseButton>
        )}

        {!showContent && (
          <CloseButton onClick={onClickHandler}>
            <Close />
          </CloseButton>
        )}
      </MobileContainer>
    </>
  );
}

// const DesktopContainer = styled('div', {
//   position: 'fixed',
//   bottom: 35,
//   right: 35,
//   transform: 'translateY(-50%)',
//   color: '$foreground',
//   zIndex: 10000,
//   display: 'none',

//   '@bp3': {
//     display: 'flex',
//   },
// });

const MobileContainer = styled('div', {});

const CloseButton = styled('a', {
  position: 'fixed',
  top: 15, // same value as the header padding
  right: 15,
  border: '1px solid $foreground',
  color: '$foreground',
  padding: '$2',
  zIndex: 10000,
  transition: 'all .2s ease-in-out',
  cursor: 'pointer',

  '&:hover': {
    opacity: 0.65,
  },
});

const BrowseButton = styled('a', {
  position: 'fixed',
  right: 20,
  bottom: 16,
  border: '1px solid $foreground',
  color: '$foreground',
  padding: '$3 $3',
  zIndex: 9,
  transition: 'all .2s ease-in-out',
  cursor: 'pointer',
  maxWidth: '37vw',

  '&:hover': {
    color: '$foreground',
  },

  '@bp3': {
    display: 'none',
  },
});
