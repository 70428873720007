import { AnnouncementBadge } from '@/components/announcement-badge';
import { Box } from '@/components/box';
import { EnterAnimation } from '@/components/enter-animaton';
import { ErrorBoundary } from '@/components/error-boundary';
import type { CaseExperienceProps } from '@/components/home/case-experience';
import { ToggleContent } from '@/components/home/case-experience/components/interface-elements/toggle-content';
import { useExperienceStore } from '@/components/home/case-experience/store';
import { FullScreenVimeo } from '@/components/home/full-screen-vimeo';
import { Layout } from '@/components/layout';
import { Navigation, NavigationContext } from '@/components/navigation';
import { StaggeredText } from '@/components/staggered-text';
import { Text } from '@/components/text';
import { ThemeSwitch } from '@/components/theme-switch';
import { VisuallyHidden } from '@/components/visually-hidden';
import { getData } from '@/lib/sanity/get-data';
import { getBadge } from '@/lib/sanity/queries/get-badge';
import { urlForImage } from '@/lib/sanity/sanity';
import { ExperienceCaseSchema } from '@/lib/schemas/experience-case';
import { MetadataSchema } from '@/lib/schemas/metadata';
import { CSS, styled } from '@/stitches.config';
import { AnimatePresence, motion } from 'framer-motion';
import type { InferGetStaticPropsType } from 'next';
import { groq } from 'next-sanity';
import { useTheme } from 'next-themes';
import dynamic from 'next/dynamic';
import { useContext, useState } from 'react';
import { z } from 'zod';

const DynamicComponentExperience = dynamic<CaseExperienceProps>(
  () =>
    import('@/components/home/case-experience').then(
      (mod) => mod.CaseExperience
    ),
  { ssr: false }
);

function IndexPage({
  homepage,
  badge,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { experienceItems, home } = homepage;

  const { title, metadata } = home;
  const {
    videoId,
    enableVideo = false,
    buttonLabel = 'Watch our story',
  } = home;

  const { showContent, currentState } = useExperienceStore((state) => ({
    showContent: state.showContent,
    currentState: state.currentState,
  }));

  const { duration } = useContext(NavigationContext);

  const [isLoaded, setIsLoaded] = useState(true);

  const { resolvedTheme } = useTheme();

  let buttonText = 'Watch our story';
  if (buttonLabel) {
    if (buttonLabel.trim() !== '') {
      buttonText = buttonLabel.trim();
    }
  }

  const showVideoButton = (videoId && enableVideo) || false;

  /**
   * By request the image through the next.js image proxy, we save on traffic to the Sanity CDN
   */
  const ogImage = metadata?.openGraphImage
    ? `https://www.cleverfranke.com/_next/image?url=${urlForImage(
        metadata.openGraphImage
      ).url()}&w=1200&q=75`
    : undefined;

  return (
    <Layout
      showFooter={false}
      {...(metadata?.title ? { title: metadata.title } : {})}
      {...(metadata?.description ? { description: metadata.description } : {})}
      ogImage={ogImage}
      badgeData={badge}
    >
      <PageContainer
        key="home"
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { delay: duration } }}
      >
        {showContent && (
          <>
            <AnimatePresence>
              {badge && badge.showOnHomepage && currentState !== 'matched' && (
                <AnnouncementBadge
                  css={positionedAnnouncementCSS}
                  badgeData={badge}
                  animate
                />
              )}
            </AnimatePresence>
            <ContentContainer>
              <HeadingAndNavigationContainer>
                <VisuallyHidden as="h1">CLEVERºFRANKE</VisuallyHidden>
                <Heading
                  as="h2"
                  theme={resolvedTheme === 'dark' ? 'dark' : 'light'}
                >
                  <StaggeredText text={title} />
                </Heading>

                <NavigationAndVideoContainer>
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      <Navigation key="home" onComplete={setIsLoaded} />
                    </motion.div>
                  </AnimatePresence>
                  {showVideoButton && (
                    <Box
                      css={{
                        my: '$7',
                        '@bp3': {
                          my: 0,
                        },
                      }}
                    >
                      <EnterAnimation enterDelayInSeconds={0.5}>
                        <FullScreenVimeo
                          buttonLabel={buttonText}
                          videoId={videoId}
                        />
                      </EnterAnimation>
                    </Box>
                  )}
                </NavigationAndVideoContainer>
              </HeadingAndNavigationContainer>
            </ContentContainer>
          </>
        )}

        {isLoaded && (
          <>
            <ErrorBoundary suppressError>
              <DynamicComponentExperience experienceItems={experienceItems} />
              <ToggleContent />
            </ErrorBoundary>
          </>
        )}
        <ThemeSwitchWrapper>
          <ThemeSwitch />
        </ThemeSwitchWrapper>
      </PageContainer>
    </Layout>
  );
}

const positionedAnnouncementCSS: CSS = {
  position: 'absolute',
  bottom: '6rem',
  right: '2rem',
  width: '8rem',
  height: '8rem',
  '@bp2': {
    width: 'min(20vh, 16rem)',
    height: 'min(20vh, 16rem)',
  },
  '@bp5': {
    bottom: '6rem',
    right: '60rem',
  },
  '@bp6': {
    right: 'max(70rem, 65vw)',
  },
};

const PageContainer = styled(motion.div, {
  height: '100dvh',
  width: '100%',
  position: 'fixed',
  left: 0,
  top: 0,
});

const ContentContainer = styled('div', {
  display: 'flex',
  minHeight: '100vh',
  width: '100%',
  px: '$6',
  pt: '$11',
  pointerEvents: 'none',

  '@bp1': {
    pt: '$12',
    alignItems: 'center',
  },

  '@bp2': {
    pt: 'initial',
    alignItems: 'center',
  },
  '@bp4': {
    px: '$8',
    pr: '$10',
  },
  '@bp5': {
    justifyContent: 'flex-end',
    mt: '-4em', //optically align.
  },
});

const Heading = styled(Text, {
  fontSize: '$6',
  fontWeight: '$normal',
  lineHeight: '$2',
  mb: '$5',
  flex: 0,
  flexDirection: 'column',
  color: '$foreground',
  whiteSpace: 'pre-line',
  '@bp1': {
    fontSize: 'clamp(2rem, 6vh, 4.5rem)',
  },
  '@bp2': {
    mb: '$4',
    mt: '$12',
    fontSize: 'clamp(2rem, 9vh, 4.5rem)',
  },
  '@bp3': {
    mt: 0,
    ml: '-0.05em',
    fontSize: 'clamp(3.5rem, 12vh, 7.15rem)',
  },

  variants: {
    theme: {
      dark: {
        filter: 'drop-shadow(1px 1px 0px rgba(255, 255, 255, 0.12))',
      },
      light: {
        filter: 'drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.12))',
      },
    },
  },
});

const NavigationAndVideoContainer = styled('div', {
  '@bp3': {
    display: 'flex',
    gap: '$7',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
  },
  '@bp5': {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },

  a: {
    color: '$foreground',
  },
});

const HeadingAndNavigationContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '30rem',

  '@bp2': {
    width: '54rem',
  },
});

const ThemeSwitchWrapper = styled('div', {
  position: 'fixed',
  bottom: 'calc($5 + env(safe-area-inset-bottom, $5))',
  left: 0,
  right: 0,
  filter: 'invert(1)',
  zIndex: 3,
  display: 'flex',
  justifyContent: 'center',
});

const HomepageSchema = z.object({
  home: z.object({
    title: z.optional(z.string()),
    videoId: z.optional(z.string()),
    enableVideo: z.optional(z.boolean()),
    buttonLabel: z.optional(z.string()),
    metadata: z.optional(MetadataSchema),
  }),
  experienceItems: z.array(
    z.object({
      title: z.string(),
      slug: z.string(),
      caseItems: z.array(ExperienceCaseSchema),
    })
  ),
});

const getHomepage = async (
  preview: boolean
): Promise<z.infer<typeof HomepageSchema>> => {
  const query = groq`
      {
      "home": *[_type == "homepage"][0]{
        title,
        videoId,
        enableVideo,
        buttonLabel,
        metadata {
          title, description, openGraphImage
        }
      },
      "experienceItems":  *[_type == "project" && isActive != false && defined(experience)] {
        title,
        "slug": slug.current,
        "caseItems": experience.caseItems
      }
    }
  `;

  const homepage = await getData(query, HomepageSchema, preview);

  return homepage;
};

export const getStaticProps = async ({ preview = false }) => {
  const homepage = await getHomepage(preview);
  const badge = await getBadge(preview);

  return {
    props: { homepage, badge },
    //           s * min
    revalidate: 60 * 60,
  };
};

export default IndexPage;
